import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Uncutsans, sans',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const PricingPlan = ({ plan }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      // Send the paymentMethod.id to your server to handle the payment
      console.log('[PaymentMethod]', paymentMethod);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{plan.name}</Typography>
        <Typography>{plan.description}</Typography>
        <Typography>${plan.price}</Typography>
        <form onSubmit={handleSubmit}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          <Button type="submit" disabled={!stripe}>
            Pay
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default PricingPlan;
