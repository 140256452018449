// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/UncutSans-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,300&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'MyCustomFont';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*html, body, #root {*/
/*  height: 100%;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  width: 100%;*/
/*}*/

/*body {*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  position: relative;  */
/*}*/

/*#root {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  min-height: 100vh;*/
/*}*/`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,2BAA2B;EAC3B,+DAAkE;EAClE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,sBAAsB;AACtB,kBAAkB;AAClB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,IAAI;;AAEJ,SAAS;AACT,uBAAuB;AACvB,mBAAmB;AACnB,4BAA4B;AAC5B,0BAA0B;AAC1B,IAAI;;AAEJ,UAAU;AACV,mBAAmB;AACnB,4BAA4B;AAC5B,uBAAuB;AACvB,IAAI","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,300&display=swap');\n\n@font-face {\n  font-family: 'MyCustomFont';\n  src: url('../public/fonts/UncutSans-Bold.woff') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n/*html, body, #root {*/\n/*  height: 100%;*/\n/*  margin: 0;*/\n/*  padding: 0;*/\n/*  width: 100%;*/\n/*}*/\n\n/*body {*/\n/*  min-height: 100vh;*/\n/*  display: flex;*/\n/*  flex-direction: column;*/\n/*  position: relative;  */\n/*}*/\n\n/*#root {*/\n/*  display: flex;*/\n/*  flex-direction: column;*/\n/*  min-height: 100vh;*/\n/*}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
